export const FEEDBACK_POPUP = 'frontend/feedback/FEEDBACK_POPUP';
export const FEEDBACK_POPUP_REMOVE = 'frontend/feedback/FEEDBACK_POPUP_REMOVE';

export function toggleFeedbackPopup(data) {
  return { type: FEEDBACK_POPUP, payload: data };
}


export function removeFeedbackPopup(data) {
  return { type: FEEDBACK_POPUP_REMOVE, payload: data };
}
