import { get } from '../../api';

export const GET_NATIVE_RATES = 'frontend/native-rates/GET_NATIVE_RATES';
export const REMOVE_NATIVE_RATES = 'frontend/native-rates/REMOVE_NATIVE_RATES';

export function getNativeCurrencyRate() {
  return (dispatch) => {
    const code = localStorage.getItem('displayCurrency');

    const countryData = window.countryData || {};

    if (!code) {
      return false;
    }

    if (code === countryData['Currency-Alphabetic-Code'] || code !== countryData['Native-Currency-Alphabetic-Code']) {
      return dispatch({ type: REMOVE_NATIVE_RATES });
    }

    return get(dispatch, `/payment/get-conversion-rate/${code}`)
      .then(({ response, data }) => {
        if (response && data) {
          return dispatch({ type: GET_NATIVE_RATES, payload: data });
        }
        return dispatch({ type: REMOVE_NATIVE_RATES });
      });
  };
}
