import store from '../store';

export const VOUCHER_MESSAGE = 'frontend/voucher-message/VOUCHER_MESSAGE';
export const RESET_VOUCHER_MESSAGE = 'frontend/voucher-message/RESET_VOUCHER_MESSAGE';
export const VOUCHER_MESSAGE_DEFAULT = 'NONE';

export const INVALID_DISCOUNT_CODE = 'Voucher code is invalid or has expired';
export const INCOMPATIBLE_DISCOUNT_CODE =
  'Some products in your basket are not compatible with this discount';
export const INDIVIDUAL_CODE_ERROR =
  'You cannot use an individual discount without a personalised code';
export const NEWSLETTER_ERROR =
  'User not on email newsletter';
export const NEWSLETTER_ERROR_MESSAGE =
  'Newsletter signup is required for coupon';
export const NEWSLETTER_ERROR_2 =
  'Unable to verify newsletter subscription, please try again';
export const NEWSLETTER_ERROR_2_MESSAGE =
  'Unable to verify newsletter subscription, please try again later';
export const MUST_BE_LOGGED_IN = 'You must be logged in to use this coupon';
export const MUST_BE_LOGGED_IN_MESSAGE =
  'You must be logged in to use this coupon';
export const NEW_CUSTOMER_VOUCHER = 'This coupon is for new customers only';
export const EXTENSION_ERROR =
  'You must have the Fanatical Chrome Browser Extension installed to use this coupon';

export function setVoucherMessage(returnData) {
  return store.dispatch({ type: VOUCHER_MESSAGE, payload: returnData });
}

export function resetVoucherMessage() {
  return store.dispatch({ type: RESET_VOUCHER_MESSAGE });
}
