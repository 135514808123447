import _get from 'lodash/get';
import jwtDecode from 'jwt-decode';

import { post } from '../../api';
import { CHALLENGE, finishLogin } from './auth';
import GeneralUtils from '../../Helpers/GeneralUtils';
import { GenerateV3Token } from '../../Helpers/Recaptcha/Recaptcha';
import { postEmail } from './email-subscribe';


export const GOOGLE_INITIAL = 'frontend/google/GOOGLE_INITIAL';
export const GOOGLE_SUCCESS = 'frontend/google/GOOGLE_SUCCESS';
export const GOOGLE_ERROR = 'frontend/google/GOOGLE_ERROR';

export const formatGoogleOneTapCreditionals = (token) => {
  let decodedToken = {};
  try {
    decodedToken = jwtDecode(token);
  } catch (e) {
    // catch logic
  }

  return {
    googleId: decodedToken.sub,
    tokenId: token,
    profileObj: {
      email: decodedToken.email,
      givenName: decodedToken.given_name,
      familyName: decodedToken.family_name,
      name: decodedToken.name,
    },
  };
};

export const fetchUserInfo = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', options);

  if (response.ok) {
    const data = await response.json();

    return {
      googleId: data.sub,
      tokenId: token,
      profileObj: {
        email: data.email,
        givenName: data.given_name,
        familyName: data.family_name,
        name: data.name,
      },
    };
  }

  return null;
};

export function doGoogleLogin(googleObject, newsletter) {
  const email = _get(googleObject, 'profileObj.email');
  const googleFirstname = _get(googleObject, 'profileObj.givenName');
  const googleSurname = _get(googleObject, 'profileObj.familyName');
  const googleName = _get(googleObject, 'profileObj.name');

  return async (dispatch) => {
    if (googleObject && googleObject.error === 'popup_closed_by_user') {
      return dispatch({ type: GOOGLE_ERROR, payload: { code: 'E701', message: googleObject.details } });
    }

    if (googleObject && googleObject.error === 'idpiframe_initialization_failed') {
      return dispatch({ type: GOOGLE_ERROR, payload: { code: 'E703', message: googleObject.details } });
    }

    if (googleObject && googleObject.error) {
      return dispatch({ type: GOOGLE_ERROR, payload: { message: googleObject.details } });
    }

    if (
      !googleObject ||
      !email ||
      !googleObject.tokenId ||
      !googleObject.googleId
    ) {
      return dispatch({ type: GOOGLE_ERROR, payload: null });
    }

    const atok = localStorage.getItem('bsatok');

    const { response, data } = await post(dispatch, '/auth/google-login-customer/', {
      googleObject: {
        email,
        googleFirstname,
        googleSurname,
        googleName,
        googleId: googleObject.googleId,
        tokenId: googleObject.tokenId,
      },
      snowplowId: GeneralUtils.getSnowplowId(),
      v3_recaptcha: await GenerateV3Token('login'),
      language: window.locale,
      atok,
    });

    if (response.status === 200) {
      dispatch({ type: GOOGLE_SUCCESS, payload: '' });
      if (newsletter) {
        dispatch(postEmail(email, 'New Account', true));
      }
      return finishLogin(data);
    }

    if (response.status === 202) {
      if (newsletter) {
        dispatch(postEmail(email, 'New Account', true));
      }
      return dispatch({ type: CHALLENGE, payload: data });
    }

    if (response.status === 400) {
      return dispatch({ type: GOOGLE_ERROR, payload: data });
    }

    return dispatch({ type: GOOGLE_ERROR, payload: { code: 'E402' } });
  };
}
