import get from 'lodash/get';

import { post, getToken } from '../../api';
import { toggleLoginModal } from './modal';
import { setIndiVoucherAction } from '../../redux/ducks/login-actions';
import checkExtensionInstalled from '../../Helpers/Extension/CheckExtensionInstalled';
import { setVoucherMessage, resetVoucherMessage } from './voucher-message';
import store from '../store';
import { toggleFeedbackPopup } from '../../redux/ducks/feedback-popups';

const ADD_VOUCHER = 'frontend/voucher/ADD_VOUCHER';
const REMOVE_VOUCHER = 'frontend/voucher/REMOVE_VOUCHER';
const RESET_VOUCHER = 'frontend/voucher/RESET_VOUCHER';

export function resetVoucher() {
  return store.dispatch({ type: RESET_VOUCHER, payload: null });
}

export function addVoucher(discountCode, indi, popToast) {
  resetVoucherMessage();
  if (indi) {
    return async (dispatch, getState) => {
      const state = getState();
      const authenticated = get(state, 'auth.authenticated', false);

      if (!authenticated) {
        store.dispatch(toggleLoginModal());
        setIndiVoucherAction(discountCode);
      } else {
        store.dispatch(addVoucher(discountCode));
      }
    };
  }

  const currentState = store.getState();
  const authenticated = get(currentState, 'auth.authenticated', false);

  if ((discountCode || '').includes('F_') && !authenticated) {
    return async (dispatch) => {
      store.dispatch(toggleLoginModal());
      setIndiVoucherAction(discountCode);
      return dispatch({ type: ADD_VOUCHER, payload: {} });
    };
  }

  return async (dispatch) => {
    let extensionCheckError;

    const path = getToken() ? '/user/discount' : '/discount';

    const request = await post(dispatch, path, { discountCode });
    const { data } = request;
    const { response } = request;

    if (data.assistant_exclusive) {
      try {
        await checkExtensionInstalled(true);
      } catch (e) {
        extensionCheckError = {
          message: 'You must have the Fanatical Browser Extension installed to use this coupon',
          status: 'Invalid discount code',
          type: 'voucher',
        };
      }
    }

    if (response.ok && !extensionCheckError) {
      const payload = {
        voucher: data,
        voucherText: discountCode,
      };

      dispatch({ type: ADD_VOUCHER, payload });
      if (popToast) store.dispatch(toggleFeedbackPopup({ type: 'success', title: 'coupon-success', message: data.name }));
      return data;
    }

    if (data && data.type === 'voucher' && data.status === 'User not on email newsletter') {
      const payload = {
        voucher: data.data,
        voucherText: discountCode,
      };

      dispatch({ type: ADD_VOUCHER, payload });
      if (popToast) store.dispatch(toggleFeedbackPopup({ type: 'success', title: 'coupon-success', message: data.name }));
      return data;
    }

    setVoucherMessage(extensionCheckError || data);
    resetVoucher();
    if (popToast) store.dispatch(toggleFeedbackPopup({ type: 'error', title: 'coupon-error', message: 'coupon-error' }));
    return {};
  };
}

export function removeVoucher() {
  resetVoucherMessage();
  return dispatch => dispatch({ type: REMOVE_VOUCHER });
}

// After completing order, remove voucher if it cannot be used again
export function removeSingleUseVoucher(order) {
  const discount = get(order, 'discounts[0]');
  if (discount) {
    if (discount.single_use || discount.first_order || discount.discount_type === 'Unique') {
      return store.dispatch(removeVoucher());
    }
  }

  return null;
}
