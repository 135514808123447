export const API_PAYMENT_RESET = 'frontend/apipayment/API_PAYMENT_RESET';
export const NEW_API_PAYMENT = 'frontend/apipayment/NEW_API_PAYMENT';
export const REMOVE_SAVED_CARD = 'frontend/apipayment/REMOVE_SAVED_CARD';

export const initialState = {};

export function newAPIPayment(apipayment) {
  return { type: NEW_API_PAYMENT, payload: apipayment };
}

export function resetAPIPayment() {
  return { type: API_PAYMENT_RESET, payload: null };
}

export function removeSavedCard(id) {
  return { type: REMOVE_SAVED_CARD, payload: id };
}
