import store from '../store';
import { fetchWishlist } from './wishlist';
import { addVoucher } from './voucher';
import { setBackendLanguage } from './languages';
import { mergeAnonWishlist } from './anon-wishlist';
import { getByCode } from '../../config/lang';

const SET_WISHLIST_ACTION = 'frontend/login-actions/SET_WISHLIST_ACTION';
const SET_WISHLIST_URL_ACTION = 'frontend/login-actions/SET_WISHLIST_URL_ACTION';
const SET_INDI_VOUCHER_ACTION = 'frontend/login-actions/SET_INDI_VOUCHER_ACTION';

export function setWishlistAction(params, productId, hit, componentType) {
  store.dispatch({ type: SET_WISHLIST_ACTION, payload: { params, productId, hit, componentType } });
}

export function setWishlistFromSlugAction(slug) {
  store.dispatch({ type: SET_WISHLIST_URL_ACTION, payload: slug });
}

export function setIndiVoucherAction(code) {
  store.dispatch({ type: SET_INDI_VOUCHER_ACTION, payload: { code } });
}

export function applyVoucher(code) {
  if (code) {
    store.dispatch(addVoucher(code));
  }
}

export function setLanguageAfterLogin() {
  const localStorageLanguage = localStorage.getItem('language');
  const language = JSON.parse(localStorageLanguage);

  if (language) {
    setBackendLanguage(language);
  } else {
    const state = store.getState();
    if (state.auth && state.auth.language && getByCode(state.auth.language.code)) {
      const userLanguage = state.auth.language;

      localStorage.setItem('language', JSON.stringify(userLanguage));

      const redirect = userLanguage.code !== window.locale;

      if (redirect) {
        const rx = new RegExp(window.locale);
        const path = window.location.pathname.replace(rx, userLanguage.code);
        window.location.href = window.location.origin + path;
      }
    }
  }
}

function detectKy() {
  const iframes = document.querySelectorAll(
    'iframe[src*="amkpcclbbgegoafihnpgomddadjhcadd"], iframe[src*="kaspersky"]',
  );
  if (iframes.length) {
    store.dispatch({ type: 'frontend/auth/DETECT_KY', payload: { hasKy: true } });
  }
}

export function afterLoginActions(isRegister = false) {
  return async (dispatch) => {
    const loginActionsState = store.getState().loginActions;
    const wishlistItemSlug = loginActionsState.wishlistItemSlug;
    const code = loginActionsState.indiVoucher;

    await mergeAnonWishlist();

    if (!wishlistItemSlug) {
      await dispatch(fetchWishlist());
    }

    detectKy();

    applyVoucher(code);
    if (!isRegister) {
      setLanguageAfterLogin();
    }
  };
}
