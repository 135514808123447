import { get, post } from '../../api';
import store from '../store';
import { resetUbisoftError, newStateUbisoftError } from './ubisoft-error';
import createQueue from '../../Helpers/Queue';

const FETCH_ORDERS = 'frontend/orders/FETCH_ORDERS';
const CHANGE_PAGE = 'frontend/orders/CHANGE_PAGE';
const CHANGE_SEARCH_TERM = 'frontend/orders/CHANGE_SEARCH_TERM';
// const ORIGIN_LINK_STATUS = 'frontend/orders/ORIGIN_LINK_STATUS';
const SELECTED_ORDER_ID = 'frontend/orders/SELECTED_ORDER_ID';

export async function checkPaidOrders() {
  const { response, data } = await get(store.dispatch, '/user/check-delete-account');
  if (response.status !== 200) {
    return { success: false };
  }

  return data;
}

export function getOrders() {
  return (dispatch) => {
    try {
      get(dispatch, '/user/orders')
        .then(({ data }) => {
          if (Array.isArray(data)) {
            dispatch({
              type: FETCH_ORDERS,
              payload: data,
            });
          }
        });
    } catch (ex) { /* */ }
  };
}

export async function getSingleOrder(orderID) {
  const { response, data } = await get(store.dispatch, `/user/orders/${orderID}`);

  if (response.status > 400) {
    throw new Error('Order Not Found');
  }

  return data;
}

export async function fetchLatestOrder() {
  const { response, data } = await get(store.dispatch, '/user/orders/latest');

  if (response.status !== 200) {
    throw new Error('Unable to fetch order');
  }

  return data;
}

export function searchOrders(searchTerm = '') {
  return { type: CHANGE_SEARCH_TERM, payload: searchTerm.toLowerCase() };
}

export function selectedOrder(selectedOrderIID) {
  return { type: SELECTED_ORDER_ID, payload: selectedOrderIID };
}

const getLocalAtok = () => {
  let localAtok = '';
  let localAtokObject = {};
  const localAtokString = localStorage.getItem('bsatok') || '';
  if (localAtokString && typeof localAtokString === 'string') {
    try {
      localAtokObject = JSON.parse(localAtokString);
    } catch (ex) { return localAtok; }
  }
  if (localAtokObject.value) {
    localAtok = localAtokObject.value;
  }
  return localAtok;
};

export const getAtok = () => {
  const state = store.getState();
  if (state.atok) {
    if (state.atok.value !== '') {
      return state.atok.value;
    }
  }
  return getLocalAtok();
};

async function processRevealKey(orderID, bundleID, item) {
  const { response, data } = await post(store.dispatch, '/user/orders/redeem', {
    oid: orderID,
    bid: bundleID,
    pid: item._id,
    serialId: item.serialId,
    iid: item.iid,
    atok: getAtok(),
  });

  if (response.ok) return data;
  return { key: 'error' };
}

async function processRevealMysteryKey(orderID, bundleID, item) {
  const { response, data } = await post(store.dispatch, '/user/orders/redeem?mysteryNameOnly=1', {
    oid: orderID,
    bid: bundleID,
    pid: item._id,
    serialId: item.serialId,
    iid: item.iid,
  });

  if (response.ok) return data;
  return data;
}

/**
 * Take a mystery bundle in an order, reveal all the keys in it
 */
export async function mysteryBundleReveal(oid, item) {
  if (item.type !== 'bundle' || !item.bundles || !item.bundles.length) {
    throw new Error('mysteryBundleReveal can only be used with bundles');
  }

  const { iid, _id: pid } = item;

  const { response, data } = await post(store.dispatch, '/user/orders/redeem?mysteryNameOnly=1', { oid, pid, iid });

  if (response.ok) {
    const order = data;

    return order;
  }
  if (!response.ok) return {};

  throw data.message;
}

// Don't initiate queue until a user tried to reveal key
let revealQueue;

export async function revealKey(orderID, bundleID, item) {
  if (!revealQueue) {
    revealQueue = createQueue(processRevealKey, 1000);
  }

  return revealQueue(orderID, bundleID, item);
}

let revealMysteryQueue;

export async function revealMysteryKey(orderID, bundleID, item) {
  if (!revealMysteryQueue) {
    revealMysteryQueue = createQueue(processRevealMysteryKey, 1000);
  }

  return revealMysteryQueue(orderID, bundleID, item);
}

export function getDownloadUrl(orderID, fileID) {
  return async (dispatch) => {
    const { response, data } = await get(dispatch, `/user/download/${orderID}/${fileID}`);
    if (response.ok) return data;
    if (!response.ok) return {};
    throw Error(response.statusText);
  };
}

export function getDownloadUrls(orderID, format) {
  return async (dispatch) => {
    const { response, data } = await get(dispatch, `/user/download/${orderID}/all/${format}`);
    if (response.ok) return data;
    if (!response.ok) return {};
    throw Error(response.statusText);
  };
}

export function changePage(page) {
  return { type: CHANGE_PAGE, payload: page };
}

// Origin Calls
export function getOriginLink(orderID) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/orders/origin-link', { oid: orderID });
    if (!response.ok) {
      return {};
    }
    return data;
  };
}

export function getOriginAccount() {
  return async (dispatch) => {
    const { data } = await get(dispatch, '/user/orders/origin-user');
    return data;
  };
}

export function getOriginFulfill(customerProductId) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/orders/origin-fulfill', {
      customerProductId,
    });

    if (!response.ok) {
      return 'An error occurred during activation please try again';
    }
    if (response.ok || data) {
      return data;
    }
    return data;
  };
}

// export function originCheckStatus(requestID) {
//   return dispatch => get(dispatch, `/orders/origin-check-status/${requestID}`)
//     .then(({ response, data }) => {
//       if (!response.ok) {
//         dispatch({ type: ORIGIN_LINK_STATUS, payload: null });
//         return false;
//       }
//       dispatch({ type: ORIGIN_LINK_STATUS, payload: null });
//       return data;
//     });
// }

// export function getOriginAccount() {
//   return async (dispatch) => {
//     const { response, data } = await post(dispatch, '/user/orders/origin-account');
// }}

// export function fulfillOriginOrder() {
//   return async (dispatch) => {
//     const { response, data } = await post(dispatch, '/user/orders/origin-fulfill-order');
// }}

/* eslint-disable arrow-body-style */
export function ubisoftRevealKey(
  orderID,
  bundleID,
  item,
  uplayId,
  uplayTicket,
  ubisoftSkaBundleItem,
  index,
  iid,
) {
  resetUbisoftError();
  const timer = setTimeout(() => {
    newStateUbisoftError({
      status: 40,
      error: 'Ubisoft Redemption Timed Out',
    });
    setTimeout(() => {
      resetUbisoftError();
    }, 5000);
  }, 30000);
  return async (dispatch) => {
    const { response } = await post(dispatch, '/user/orders/ubisoft-redeem', {
      oid: orderID,
      bid: bundleID,
      item,
      pid: item._id,
      uplayId,
      uplayTicket,
      ubisoftSkaBundleItem,
      index,
      iid,
    });

    if (timer) {
      clearTimeout(timer);
    }

    if (response.ok) {
      return window.location.reload(true);
    }

    return newStateUbisoftError({
      status: 10,
      error: 'Ubisoft Redemption Error',
    });
  };
}

export async function getKeys() {
  const { data, response } = await get([], '/user/keys');
  if (response.ok && data) {
    return data;
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return [];
}

export async function requestRefund(orderID, reason) {
  const { response, data } = await post(store.dispatch, `/user/orders/refund/${orderID}`, {
    reason,
  });

  if (response.ok) return data;

  throw new Error(data.message || 'Unexpected Error');
}
