import store from '../store';
import { post } from '../../api';
import { resetRedeemConfirm, newRedeemConfirm } from './redeem-confirm';
import { toggleRedeemRequestModal } from './modal';

export const RESET_ATOK = 'frontend/atok/RESET_ATOK';
export const NEW_ATOK = 'frontend/ratok/NEW_ATOK';

export function resetAtok() {
  localStorage.setItem('bsatok', null);
  store.dispatch({ type: RESET_ATOK, payload: null });
}

export function newStateAtok(newValue) {
  if (!newValue) {
    return false;
  }
  localStorage.setItem('bsatok', newValue);
  return store.dispatch({ type: NEW_ATOK, payload: { value: newValue } });
}

let returnMethod = () => {};

export function setReturnMethod(method) {
  returnMethod = method;
}

export function sendAtokCode(code) {
  if (typeof code !== 'string') {
    return false;
  }
  const sendCode = code.trim().toUpperCase();
  return async (dispatch) => {
    resetRedeemConfirm();
    const { response, data } = await post(dispatch, '/user/atok/code', {
      code: sendCode,
    });
    if (response.ok) {
      if (data !== {}) {
        newStateAtok(data);
        dispatch(toggleRedeemRequestModal());
        return window.setTimeout(() => {
          returnMethod();
          window.setTimeout(() => {
            returnMethod = () => {};
          }, 200);
        }, 200);
      }
    }
    newRedeemConfirm({ status: 10, error: 'Error with code please resubmit' });
    return false;
  };
}
