import get from 'lodash/get';

import { post } from '../../api';
import State from '../../Helpers/State';
import GeneralUtils from '../../Helpers/GeneralUtils';
import store from '../store';
import { toggleExitIntentStarDeal, toggleExitIntentFandom, toggleExitIntentEmail } from './modal';

const EXIT_INTENT_STAR_DEAL_STATE = 'frontend/exit-intent/EXIT_INTENT_STAR_DEAL_STATE';
const EXIT_INTENT_EMAIL_CART_STATE = 'frontend/exit-intent/EXIT_INTENT_EMAIL_CART_STATE';
const EXIT_INTENT_FANDOM_STATE = 'frontend/exit-intent/EXIT_INTENT_FANDOM_STATE';
const VIEW_EMAIL_BASKET = 'frontend/exit-intent/VIEW_EMAIL_BASKET';
const VIEW_STAR_DEAL = 'frontend/exit-intent/VIEW_STAR_DEAL';
const VIEW_FANDOM = 'frontend/exit-intent/VIEW_FANDOM';
const SUBMIT_EMAIL_BASKET = 'frontend/exit-intent/SUBMIT_EMAIL_BASKET';


export function exitIntentStarDeal(leftBrowserStarDeal) {
  if (leftBrowserStarDeal) {
    store.dispatch({ type: VIEW_STAR_DEAL });
    store.dispatch(toggleExitIntentStarDeal());
  }
  return { type: EXIT_INTENT_STAR_DEAL_STATE, payload: leftBrowserStarDeal };
}

export function exitIntentFandom(leftBrowserFandom) {
  if (leftBrowserFandom) {
    store.dispatch({ type: VIEW_FANDOM });
    store.dispatch(toggleExitIntentFandom());
  }
  return { type: EXIT_INTENT_FANDOM_STATE, payload: leftBrowserFandom };
}

export function exitIntentEmailCart(leftBrowserEmailCart) {
  if (leftBrowserEmailCart) {
    store.dispatch({ type: VIEW_EMAIL_BASKET });
    store.dispatch(toggleExitIntentEmail());
  }
  return { type: EXIT_INTENT_EMAIL_CART_STATE, payload: leftBrowserEmailCart };
}

export function exitIntentEmailCartDispatch(email) {
  /* eslint-disable consistent-return */
  return async (dispatch) => {
    const voucher = State.getState('voucher');
    const discountCode = get(voucher, 'voucher.code', undefined);
    dispatch({ type: SUBMIT_EMAIL_BASKET });
    const { response } = await post(dispatch, '/exit-intent/email-cart', {
      email,
      languageCode: window.locale,
      snowplowId: GeneralUtils.getSnowplowId(),
      discountCode,
    });

    return response.ok ? 'success' : 'failure';
  };
}
